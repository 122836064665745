import { Injectable } from '@angular/core';
import {
  CustomerApiService as CustomerV2ApiService,
  CustomerLocationFunction,
  GetCustomerPath,
  GetCustomerQuery,
  GetCustomerResp
} from '@xpo-ltl-2.0/sdk-customer';
import { AcctId, CustomerAccountTypeCd, CustomerIdTypeCd } from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseValidation } from '../classes/ResponseValidation';
import { ErrorMessageActions } from '../enums/error-message-actions.enum';
import { ErrorHandlingService } from './error-handling.service';

@Injectable()
export class AccountService {
  private static readonly MAD_CODE_CANNOT_BE_EMPTY = 'The MAD Code cannot be empty';
  private static readonly INVALID_MAD_CODE = 'Invalid MAD Code';
  private static readonly GET_CUSTOMER_RESP_ERROR_MESSAGE = 'Customer account not found for MAD Code:';
  private static readonly ACCOUNT_INFO = 'Account Information';

  private madCode: string;

  constructor(private customerV2ApiService: CustomerV2ApiService, private errorHandling: ErrorHandlingService) {}

  /**
   *
   * @param account
   */
  static accountToString(account: AcctId): string {
    return `${account.acctName} (${account.acctMadCd.toUpperCase()})`;
  }

  /**
   *
   * @param madCode
   */
  static formatMadCodeAccount(madCode: string): string {
    return madCode.toUpperCase();
  }

  /**
   *
   * @param madCode
   */
  static validateMadCode(madCode: string): ResponseValidation {
    const regExp = /^[a-z0-9\s]+$/i;
    const madCodeIsValid = new ResponseValidation(true, '');
    if (!madCode) {
      madCodeIsValid.setIsValid(false);
      madCodeIsValid.setMessage(AccountService.MAD_CODE_CANNOT_BE_EMPTY);
    } else if (madCode.length !== 11) {
      madCodeIsValid.setIsValid(false);
      madCodeIsValid.setMessage('');
    } else if (!madCode.match(regExp)) {
      madCodeIsValid.setIsValid(false);
      madCodeIsValid.setMessage(AccountService.INVALID_MAD_CODE);
    }
    return madCodeIsValid;
  }

  /**
   *
   * @param madCode
   */
  getAccountDetails(madCode: string): Observable<GetCustomerResp> {
    const pathParams: GetCustomerPath = new GetCustomerPath();
    const queryParams: GetCustomerQuery = new GetCustomerQuery();
    pathParams.id = madCode.trim();
    queryParams.customerIdTypeCd = CustomerIdTypeCd.MAD_CODE;

    return this.customerV2ApiService.getCustomer(pathParams, queryParams);
  }

  /**
   *
   * @param madCode
   */
  getAccount(madCode: string): ResponseValidation {
    this.madCode = madCode.trim();
    const validationAccount = AccountService.validateMadCode(this.madCode);
    if (validationAccount.getIsValid()) {
      validationAccount.setResponse(
        this.getAccountDetails(this.madCode).pipe(
          map((getCustomerResp: GetCustomerResp) => this.buildAcctIdFromGetCustomerResp(getCustomerResp))
        )
      );
    }
    return validationAccount;
  }

  /**
   *
   * @param getCustomerResp
   */
  private buildAcctIdFromGetCustomerResp(getCustomerResp: GetCustomerResp): AcctId {
    const customerInfo: CustomerLocationFunction = this.findAccountInfoFromGetCustomerResp(getCustomerResp);
    const acctInfo: AcctId = new AcctId();
    if (customerInfo) {
      acctInfo.acctInstId = customerInfo.legacyCisCustomerNbr?.toString();
      acctInfo.acctMadCd = customerInfo.madCd;
      acctInfo.acctName = getCustomerResp.customerLocation?.party1?.partyName;
      acctInfo.acctPartyRole = customerInfo.functionCd;
      acctInfo.creditStatCd = customerInfo.creditStatusCd;
      acctInfo.nationalAccountInd = customerInfo.acctTypeCd === CustomerAccountTypeCd.NATIONAL_ACCOUNT;
    } else {
      this.errorHandling.handleResponseError(
        `${AccountService.GET_CUSTOMER_RESP_ERROR_MESSAGE} ${this.madCode}.`,
        ErrorMessageActions.GETTING,
        AccountService.ACCOUNT_INFO
      );
    }

    return acctInfo;
  }

  private findAccountInfoFromGetCustomerResp(getCustomerResp: GetCustomerResp): CustomerLocationFunction {
    return getCustomerResp?.customerLocation?.customerLocationFunction?.find(
      (customer: CustomerLocationFunction) => customer.madCd === this.madCode
    );
  }
}
